import 'bootstrap/dist/css/bootstrap.min.css'
import HomeLoggedOut from './pages/HomeLoggedOut'
import Spinner from './components/Spinner'

import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import ComingSoon from './pages/ComingSoon'
import { checkUserAuthentication } from './utils/auth'
import HomeLoggedIn from './pages/HomeLoggedIn'
import Level from './pages/Level'
import ItemPage from './pages/ItemPage'
import Overview from './pages/Overview'
import LessonPage from './pages/LessonPage'
import ReviewPage from './pages/ReviewPage'
import PaymentLanding from './pages/PaymentLanding'
import { UserProvider } from './session/UserContext'
import {
  getProgressFromLocalStorage,
  updateUserProgressBatch,
  storeProgressInLocalStorage,
} from './utils/api'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const authStatus = await checkUserAuthentication()
      setIsAuthenticated(authStatus)
      setLoading(false)
    }
    fetchAuthStatus()

    // Handle sync before the user closes the browser/tab
    const handleBeforeUnload = () => {
      const dirtyProgress = getProgressFromLocalStorage('dirtyProgress') || []
      if (dirtyProgress.size > 0) {
        updateUserProgressBatch(Array.from(dirtyProgress.values()))
          .then(() => {
            dirtyProgress.clear()
            storeProgressInLocalStorage('dirtyProgress', dirtyProgress)
          })
          .catch((err) => console.error('Failed to update progress:', err))
      }
    }

    // Attach event listener for browser/tab close
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <div>
      <Routes>
        {/* Unauthenticated Routes */}
        <Route path='/' element={<HomeLoggedOut />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/signin' element={<SignIn setIsAuthenticated={setIsAuthenticated} />} />

        {/* Authenticated Routes wrapped with UserProvider */}
        <Route
          path='/dashboard'
          element={
            isAuthenticated ? (
              <UserProvider>
                <HomeLoggedIn />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/coming-soon'
          element={
            isAuthenticated ? (
              <UserProvider>
                <ComingSoon />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/subscription'
          element={
            isAuthenticated ? (
              <UserProvider>
                <PaymentLanding />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/level/:levelNumber'
          element={
            isAuthenticated ? (
              <UserProvider>
                <Level />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/radicals/:itemSymbol'
          element={
            isAuthenticated ? (
              <UserProvider>
                <ItemPage itemType='radical' />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/hanzi/:itemSymbol'
          element={
            isAuthenticated ? (
              <UserProvider>
                <ItemPage itemType='hanzi' />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/vocabulary/:itemSymbol'
          element={
            isAuthenticated ? (
              <UserProvider>
                <ItemPage itemType='vocabulary' />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/overview/:contentType'
          element={
            isAuthenticated ? (
              <UserProvider>
                <Overview />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/lesson'
          element={
            isAuthenticated ? (
              <UserProvider>
                <LessonPage />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
        <Route
          path='/review'
          element={
            isAuthenticated ? (
              <UserProvider>
                <ReviewPage />
              </UserProvider>
            ) : (
              <Navigate to='/signin' />
            )
          }
        />
      </Routes>
    </div>
  )
}

export default App
