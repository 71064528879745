import React from 'react'
import './DropdownMenu.css'
import PropTypes from 'prop-types'

function DropdownMenu({ title, hoverText, sections }) {
  const isLevels = title === 'Levels'
  return (
    <div className={`header__dropdown ${isLevels ? 'levels-dropdown' : ''}`}>
      <button className='header__button'>
        <span className='button-text'>{title}</span>
        <span className='button-text-hover'>{hoverText}</span>
      </button>
      <div className='header__dropdown-menu'>
        {sections.map((section, index) => (
          <div key={index} className='dropdown-section'>
            {isLevels ? (
              <>
                <div>{section.label}</div>
                <div className='dropdown-grid'>
                  {section.items.map((item, idx) => (
                    <a key={idx} href={item.link}>
                      {item.label}
                    </a>
                  ))}
                </div>
              </>
            ) : (
              <div className='dropdown-grid'>
                {section.items.map((item, idx) => (
                  <a key={idx} href={item.link}>
                    <span className='dropdown-label'>{section.label}</span>{' '}
                    <span>{item.label}</span>
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

DropdownMenu.propTypes = {
  title: PropTypes.string.isRequired,
  hoverText: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default DropdownMenu
