import React, { useState, useEffect } from 'react'
import './RegisteredComingSoon.css'

function RegisteredComingSoon() {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })

  useEffect(() => {
    const targetDate = new Date('2024-10-01T00:00:00')

    const updateCountdown = () => {
      const now = new Date()
      const difference = targetDate - now

      if (difference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 })
        return
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)

      setTimeLeft({ days, hours, minutes, seconds })
    }

    updateCountdown()
    const intervalId = setInterval(updateCountdown, 1000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <section className='coming-soon'>
      <div className='coming-soon__content'>
        <h1 className='coming-soon__title'>Congratulations!</h1>
        <p className='coming-soon__text'>You&apos;ve successfully registered. 🎉</p>
        <p className='coming-soon__text'>
          We&apos;re working hard to bring you an exceptional experience and premium learning
          materials. Everything will be ready by September 1st, 2024!
        </p>
        <p className='coming-soon__text'>We can&apos;t wait to see you then.</p>
        <div className='coming-soon__countdown'>
          <h2 className='coming-soon__countdown-title'>Countdown to Launch:</h2>
          <div className='coming-soon__countdown-timer'>
            <div className='coming-soon__countdown-item'>
              <span className='coming-soon__countdown-number'>{timeLeft.days}</span>
              <span className='coming-soon__countdown-label'>Days</span>
            </div>
            <div className='coming-soon__countdown-item'>
              <span className='coming-soon__countdown-number'>{timeLeft.hours}</span>
              <span className='coming-soon__countdown-label'>Hours</span>
            </div>
            <div className='coming-soon__countdown-item'>
              <span className='coming-soon__countdown-number'>{timeLeft.minutes}</span>
              <span className='coming-soon__countdown-label'>Minutes</span>
            </div>
            <div className='coming-soon__countdown-item'>
              <span className='coming-soon__countdown-number'>{timeLeft.seconds}</span>
              <span className='coming-soon__countdown-label'>Seconds</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisteredComingSoon
