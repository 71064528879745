import React from 'react'
import ItemDetail from '../components/ItemDetail'
import HeaderLoggedIn from '../components/HeaderLoggedIn'
import PropTypes from 'prop-types'

function ItemPage({ itemType }) {
  return (
    <div>
      <HeaderLoggedIn />
      <ItemDetail itemType={itemType} />
    </div>
  )
}

ItemPage.propTypes = {
  itemType: PropTypes.string.isRequired,
}

export default ItemPage
