// ReviewCard.js
import React from 'react'
import './ReviewCard.css'
import { useNavigate } from 'react-router-dom'

const ReviewCard = () => {
  const navigate = useNavigate()
  return (
    <div className='card review-card'>
      <div className='review-card__header'>
        <h2 className='review-card__title'>Reviews</h2>
        {/* <span className='review-card__count'>20</span> */}
      </div>
      <p className='review-card__description'>Review these items to level them up!</p>
      <button className='review-card__button' onClick={() => navigate('/review')}>
        Start Reviews
      </button>
      <div className='review-card__image'></div>
    </div>
  )
}

export default ReviewCard
