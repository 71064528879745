import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API_LEARNING_CONTENT_ENDPOINT } from '../constants/common'
import Spinner from './Spinner'
import './ItemDetail.css'
import PropTypes from 'prop-types'

function ItemDetail({ itemType }) {
  const { itemSymbol } = useParams()
  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchItem = async () => {
      try {
        let endpoint = ''
        switch (itemType) {
          case 'hanzi':
            endpoint = `${API_LEARNING_CONTENT_ENDPOINT}/LearningContent/hanziDetails/${itemSymbol}`
            break
          case 'radical':
            endpoint = `${API_LEARNING_CONTENT_ENDPOINT}/LearningContent/radicalDetails/${itemSymbol}`
            break
          case 'vocabulary':
            endpoint = `${API_LEARNING_CONTENT_ENDPOINT}/LearningContent/vocabularyDetails/${itemSymbol}`
            break
          default:
            throw new Error('Invalid item type')
        }

        const response = await fetch(endpoint)
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`)
        }
        const data = await response.json()
        setItem(data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchItem()
  }, [itemType, itemSymbol])

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (!item) {
    return <div>{itemType.charAt(0).toUpperCase() + itemType.slice(1)} not found</div>
  }

  return (
    <div className='item-detail-page'>
      <div className='item-header'>
        <div className='item-icon-box'>
          <div className='item-icon'>
            <h1>{item.symbol}</h1>
          </div>
        </div>
        <div className='item-name'>
          <h2>{item.meaning}</h2>
        </div>
        {item.mnemonics && (
          <div className='item-mnemonics'>
            <h3>Mnemonic</h3>
            <p>{item.mnemonics}</p>
          </div>
        )}
      </div>
    </div>
  )
}

ItemDetail.propTypes = {
  itemType: PropTypes.string.isRequired,
}

export default ItemDetail
