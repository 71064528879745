export const ProficiencyLevelToSRSTime = (proficiencyLevel) => {
  switch (proficiencyLevel) {
    case 0:
      return 0 // first review immediately
    case 1:
      return 2 * 60 * 60 * 1000 // second review in 2 hours
    case 2:
      return 6 * 60 * 60 * 1000 // third review in 6 hours
    case 3:
      return 24 * 60 * 60 * 1000 // fourth review in 1 day
    case 4:
      return 2 * 24 * 60 * 60 * 1000 // fifth review in 2 days
    case 5:
      return 4 * 24 * 60 * 60 * 1000 // sixth review in 4 days
    case 6 - 8:
      return 7 * 24 * 60 * 60 * 1000 // review in 7 days
    case 9 - 12:
      return 15 * 24 * 60 * 60 * 1000 // review in 15 days
    case 13 - 18:
      return 30 * 24 * 60 * 60 * 1000 // review in 30 days
    case 19 - 25:
      return 30 * 24 * 60 * 60 * 1000 // review in 30 days
    case 26 - 30:
      return 60 * 24 * 60 * 60 * 1000 // review in 60 days
    case 31 - 50:
      return 180 * 24 * 60 * 60 * 1000 // review in 180 days
    case 51 - 100:
      return 365 * 24 * 60 * 60 * 1000 // review in 1 year
    default:
      return 0
  }
}
