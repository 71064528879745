import React from 'react'
import SignInComponent from '../components/SignInComponent'
import PropTypes from 'prop-types'

function SignIn({ setIsAuthenticated }) {
  return (
    <div className='App'>
      <SignInComponent setIsAuthenticated={setIsAuthenticated} />
    </div>
  )
}

SignIn.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired,
}

export default SignIn
