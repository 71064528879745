import React, { useState, useEffect } from 'react'
import './QuizCard.css'
import PropTypes from 'prop-types'

const QuizCard = ({ currentItem, onNextItem, onIncorrectAnswer, isLastItem }) => {
  const [userInput, setUserInput] = useState('')
  const [feedback, setFeedback] = useState(null)
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [isFlipped, setIsFlipped] = useState(false)

  // Reset input and feedback when the currentItem changes
  useEffect(() => {
    setUserInput('')
    setFeedback(null)
    setCorrectAnswer('')
    setIsFlipped(false) // Ensure flip state is reset
  }, [currentItem])

  const handleInputChange = (e) => {
    setUserInput(e.target.value)
  }

  const handleCheckAnswer = () => {
    if (userInput.trim().toLowerCase() === currentItem.meaning.toLowerCase()) {
      setFeedback('Correct!')
      setCorrectAnswer('')
    } else {
      setFeedback('Incorrect. Please try again.')
      setCorrectAnswer(currentItem.meaning)
      onIncorrectAnswer(currentItem)
    }
    setUserInput('')
  }

  const handleFlip = () => {
    setIsFlipped(!isFlipped) // Toggle flip state
  }

  return (
    <div className='quiz-card'>
      <div className='quiz-card__container'>
        <div className='quiz-card__symbol'>{currentItem.symbol}</div>

        {/* Input field for answer */}
        {feedback !== 'Correct!' && (
          <>
            <div className='quiz-card__input'>
              <input
                type='text'
                value={userInput}
                onChange={handleInputChange}
                placeholder='Enter the meaning'
              />
            </div>
            <button className='quiz-card__button' onClick={handleCheckAnswer}>
              Check
            </button>
          </>
        )}

        {/* Feedback and flip card */}
        {feedback && (
          <div className='quiz-card__feedback'>
            <p>{feedback}</p>

            {correctAnswer && (
              <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
                <div className='flip-card-inner'>
                  <div className='flip-card-front'>Remind me the meaning</div>
                  <div className='flip-card-back'>{correctAnswer}</div>
                </div>
              </div>
            )}

            {/* "Next" or "Finish" button */}
            {feedback === 'Correct!' && (
              <button className='quiz-card__next-button' onClick={onNextItem}>
                {isLastItem ? 'Finish' : 'Next'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

QuizCard.propTypes = {
  currentItem: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    meaning: PropTypes.string.isRequired,
  }).isRequired,
  onNextItem: PropTypes.func.isRequired,
  onIncorrectAnswer: PropTypes.func.isRequired,
  isLastItem: PropTypes.bool.isRequired,
}

export default QuizCard
