import React from 'react'
import './Subscription.css'
import Checkout from './Checkout'

function Subscription() {
  return (
    <div className="subscription">
      <h1 className="subscription__title">Subscribe</h1>
      <p className="subscription__description">
        We have three simple plans that give you access to all levels of radicals, hanzi, and vocabulary.
      </p>
      <div className="subscription__plans">
        <div className="subscription__plan">
          <h2>$9.95 USD</h2>
          <p>Full access to all available levels of radicals, hanzi, and vocabulary while your subscription is active</p>
          <p>Recurring charge every 1 month</p>
          <Checkout buttonText="Subscribe" priceId="price_9_usd" />
        </div>
        <div className="subscription__plan">
          <h2>$99.50 USD</h2>
          <p>Full access to all available levels of radicals, hanzi, and vocabulary while your subscription is active</p>
          <p>Save up to $20/year compared to monthly</p>
          <p>Recurring charge every 1 year</p>
          <Checkout buttonText="Subscribe" priceId="price_99_usd" />
        </div>
        <div className="subscription__plan">
          <h2>$299.00 USD</h2>
          <p>Full access to all available levels of radicals, hanzi, and vocabulary</p>
          <p>One-time charge</p>
          <Checkout buttonText="Purchase" priceId="price_299_usd" />
        </div>
      </div>
    </div>
  )
}

export default Subscription



