import React, { useState, useEffect } from 'react'
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js'
import { Link, useNavigate } from 'react-router-dom'
import { awsConfig } from '../aws-config'
import './SignUpComponent.css'
import { registerUser } from '../utils/api'
import PropTypes from 'prop-types'

const poolData = {
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.clientId,
}

const userPool = new CognitoUserPool(poolData)

function SignUpComponent() {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPasswordHint, setShowPasswordHint] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState('')
  const [timer, setTimer] = useState(30)
  const [isResendDisabled, setIsResendDisabled] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    setError('')
  }, [username, email, password, verificationCode])

  useEffect(() => {
    let interval
    if (isSubmitted) {
      setIsResendDisabled(true) // Disable the button initially
      setTimer(30) // Reset the timer to 30 seconds

      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval) // Clear the interval when the timer reaches 0
            setIsResendDisabled(false) // Enable the button
          }
          return prevTimer - 1
        })
      }, 1000) // Decrease the timer every second
    }

    return () => clearInterval(interval) // Clean up the interval on component unmount
  }, [isSubmitted])

  const handleSignUp = async (e) => {
    e.preventDefault()
    const attributeList = [
      { Name: 'email', Value: email },
      { Name: 'preferred_username', Value: username },
    ]

    userPool.signUp(email, password, attributeList, null, async (err, data) => {
      if (err) {
        setError(err.message || JSON.stringify(err))
        return
      }
      const userSub = data.userSub

      try {
        await registerUser(email, username, userSub)
        alert('Sign up successful. Please check your email for the verification code.')
        setIsSubmitted(true)
      } catch (error) {
        setError('Failed to register user in the backend')
        console.error(error)
      }
    })
  }

  const handleVerify = (e) => {
    e.preventDefault()
    const user = new CognitoUser({ Username: email, Pool: userPool })

    user.confirmRegistration(verificationCode, true, async (err) => {
      if (err) {
        setError(err.message || JSON.stringify(err))
        return
      }
      alert('Account verified successfully! Please sign in.')
      navigate('/signin')
    })
  }

  const handleResendVerificationCode = () => {
    const user = new CognitoUser({ Username: email, Pool: userPool })

    user.resendConfirmationCode((err) => {
      if (err) {
        setError(err.message || JSON.stringify(err))
        return
      }
      alert('Verification code resent. Please check your email.')
      setIsResendDisabled(true) // Disable the button again after resending
      setTimer(30) // Restart the timer
    })
  }

  const passwordRequirements = {
    length: password.length >= 8,
    number: /\d/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
  }

  return (
    <div className='sign-up'>
      <div className='sign-up__container'>
        <h2 className='sign-up__title'>{isSubmitted ? 'Verify Your Account' : 'Sign Up'}</h2>
        {!isSubmitted ? (
          <form className='sign-up__form' onSubmit={handleSignUp}>
            <div className='sign-up__form-group'>
              <label className='sign-up__label' htmlFor='username'>
                Username
              </label>
              <input
                className='sign-up__input'
                id='username'
                type='text'
                placeholder='ChineseMaster'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className='sign-up__form-group'>
              <label className='sign-up__label' htmlFor='email'>
                Email
              </label>
              <input
                className='sign-up__input'
                id='email'
                type='email'
                placeholder='qilin@qilinchinese.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className='sign-up__form-group'>
              <label className='sign-up__label' htmlFor='password'>
                Password
              </label>
              <input
                className='sign-up__input'
                id='password'
                type='password'
                placeholder='ILoveLearningHanzi2024!'
                value={password}
                onFocus={() => setShowPasswordHint(true)}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {showPasswordHint && <PasswordHint requirements={passwordRequirements} />}
            </div>
            <button className='sign-up__primary-button' type='submit'>
              Sign Up
            </button>
            {error && <p className='sign-up__error'>{error}</p>}
            <div className='sign-up__link'>
              <p>
                Already have an account?{' '}
                <Link className='sign-up__secondary-button' to='/signin'>
                  Sign In
                </Link>
              </p>
            </div>
          </form>
        ) : (
          <form className='sign-up__form' onSubmit={handleVerify}>
            <div className='sign-up__form-group'>
              <input
                className='sign-up__input'
                id='verificationCode'
                type='text'
                placeholder='123456'
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
            </div>
            <button className='sign-up__primary-button' type='submit'>
              Verify
            </button>
            {error && <p className='sign-up__error'>{error}</p>}
            {/* TODO: make this a text */}
            <button
              className={
                isResendDisabled
                  ? 'sign-up__secondary-button--disabled'
                  : 'sign-up__secondary-button'
              }
              type='button'
              onClick={handleResendVerificationCode}
              disabled={isResendDisabled}
            >
              {isResendDisabled ? `Resend code in ${timer}s` : 'Resend verification code'}
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

function PasswordHint({ requirements }) {
  return (
    <div className='password-hint'>
      <p className='password-hint__title'>Password must contain:</p>
      <ul className='password-hint__list'>
        <PasswordRequirement fulfilled={requirements.length} text='At least 8 characters' />
        <PasswordRequirement fulfilled={requirements.number} text='At least 1 number' />
        <PasswordRequirement
          fulfilled={requirements.specialChar}
          text='At least 1 special character'
        />
        <PasswordRequirement
          fulfilled={requirements.uppercase}
          text='At least 1 uppercase letter'
        />
        <PasswordRequirement
          fulfilled={requirements.lowercase}
          text='At least 1 lowercase letter'
        />
      </ul>
    </div>
  )
}

PasswordHint.propTypes = {
  requirements: PropTypes.shape({
    length: PropTypes.bool.isRequired,
    number: PropTypes.bool.isRequired,
    specialChar: PropTypes.bool.isRequired,
    uppercase: PropTypes.bool.isRequired,
    lowercase: PropTypes.bool.isRequired,
  }).isRequired,
}

function PasswordRequirement({ fulfilled, text }) {
  return (
    <li className={`password-hint__item ${fulfilled ? 'password-hint__item--valid' : ''}`}>
      {fulfilled ? (
        <span className='password-hint__check'>&#10003;</span>
      ) : (
        <span className='password-hint__bullet'>&#8226;</span>
      )}
      <span className='password-hint__text'>{text}</span>
    </li>
  )
}

PasswordRequirement.propTypes = {
  fulfilled: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
}

export default SignUpComponent
