import React from 'react'
import OverviewContent from '../components/OverviewContent'
import HeaderLoggedIn from '../components/HeaderLoggedIn'

function Overview() {
  return (
    <div>
      <HeaderLoggedIn />
      <OverviewContent contentType='radicals' />
    </div>
  )
}

export default Overview
