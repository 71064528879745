import React, { useState, useEffect } from 'react'
import {
  getDataFromLocalStorage,
  getProgressFromLocalStorage,
  storeProgressInLocalStorage,
  updateUserProgressBatch,
} from '../utils/api'
import './ComposeLesson.css'
import { ProficiencyLevelToSRSTime } from '../utils/helper'
import Spinner from './Spinner'
import QuizCard from './QuizCard'

const ComposeLesson = () => {
  const [lessonElements, setLessonElements] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [mode, setMode] = useState('lesson')
  const [lessonsCompleted, setLessonsCompleted] = useState(false)
  const [quizIndex, setQuizIndex] = useState(0)

  useEffect(() => {
    const composeLesson = () => {
      try {
        const userProgress = getProgressFromLocalStorage('userProgress') || new Map()
        const dirtyProgress = getProgressFromLocalStorage('dirtyProgress') || new Map()
        const levelData = getDataFromLocalStorage('levelData') || []

        // Separate radicals and Hanzi
        const radicals = levelData.filter((item) => item.SK.startsWith('RADICAL#'))
        const hanzi = levelData.filter((item) => item.SK.startsWith('HANZI#'))

        // Radicals not yet passed (proficiencyLevel < 5)
        const pendingRadicals = radicals.filter((item) => {
          const progress = userProgress.get(item.SK)
          return progress && progress.ProficiencyLevel < 5
        })

        // Radicals not yet learned (proficiencyLevel === 0)
        const newRadicals = radicals.filter((item) => {
          const progress = userProgress.get(item.SK)
          return !progress || progress.ProficiencyLevel === 0
        })

        // Hanzi not yet learned (not in userProgress)
        const newHanzi = hanzi.filter((item) => !userProgress.has(item.SK))

        let newElements = []

        // If there are new radicals to learn, prioritize them
        if (newRadicals.length > 0) {
          // If there are less than 10 radicals left, add all to this batch, otherwise pick the first 5
          if (newRadicals.length < 10) {
            newElements = newRadicals
          } else {
            newElements = newRadicals.slice(0, 5)
          }
        } else if (pendingRadicals.length > 0) {
          // Radicals are introduced but not all are passed, don't show Hanzi yet
          setLessonsCompleted(true)
          return
        } else if (newHanzi.length > 0) {
          // All radicals passed, show Hanzi
          // If there are less than 10 hanzi left, add all to this batch, otherwise pick the first 5
          if (newHanzi.length < 10) {
            newElements = newHanzi
          } else {
            newElements = newHanzi.slice(0, 5)
          }
        }

        if (newElements.length === 0) {
          setLessonsCompleted(true)
        } else {
          const now = Date.now()
          const userUUID = getDataFromLocalStorage('userInfo').UserUUID

          newElements.forEach((item) => {
            const progressEntry = {
              UserUUID: userUUID,
              ElementId: item.SK,
              ProficiencyLevel: 0,
              LastReviewTime: now,
              NextReviewTime: now + ProficiencyLevelToSRSTime(0),
            }

            userProgress.set(item.SK, progressEntry)
            dirtyProgress.set(item.SK, progressEntry)
          })

          storeProgressInLocalStorage('userProgress', userProgress)
          storeProgressInLocalStorage('dirtyProgress', dirtyProgress)

          setLessonElements(newElements)
        }
      } catch (err) {
        setError('Failed to load lesson items')
      } finally {
        setLoading(false)
      }
    }

    composeLesson()
  }, [])

  const handleNext = () => {
    if (currentIndex < lessonElements.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      setMode('quiz')
    }
  }

  const handleQuizNextItem = () => {
    if (quizIndex < lessonElements.length - 1) {
      setQuizIndex(quizIndex + 1)
    } else {
      handleQuizCompletion()
    }
  }

  const handleIncorrectAnswer = () => {
    console.log('handleIncorrectAnswer')
  }

  const handleQuizCompletion = () => {
    const userProgress = getProgressFromLocalStorage('userProgress') || new Map()
    const dirtyProgress = getProgressFromLocalStorage('dirtyProgress') || new Map()
    const now = Date.now()

    lessonElements.forEach((element) => {
      const progressEntry = userProgress.get(element.SK)
      progressEntry.ProficiencyLevel = 1
      progressEntry.LastReviewTime = now
      progressEntry.NextReviewTime = now + ProficiencyLevelToSRSTime(1)
      userProgress.set(element.SK, progressEntry)

      dirtyProgress.set(element.SK, progressEntry)
    })

    storeProgressInLocalStorage('userProgress', userProgress)
    storeProgressInLocalStorage('dirtyProgress', dirtyProgress)

    if (dirtyProgress.size > 0) {
      updateUserProgressBatch(Array.from(dirtyProgress.values()))
        .then(() => {
          dirtyProgress.clear()
          storeProgressInLocalStorage('dirtyProgress', dirtyProgress)
        })
        .catch((err) => console.error('Failed to update progress:', err))
    }

    setLessonsCompleted(true)
  }

  const handlePrev = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1)
  }

  if (loading) return <Spinner />
  if (error) return <div>Error: {error}</div>
  if (lessonsCompleted)
    return <div>You have finished all the available lessons for now. Great job!</div>

  const currentElement = lessonElements[currentIndex]

  return mode === 'lesson' ? (
    <div className='compose-lesson'>
      <div className='compose-lesson__container'>
        <h2 className='compose-lesson__title'>Your Lesson</h2>
        <div className='lesson-element'>
          <div className='lesson-element__symbol'>{currentElement.symbol}</div>
          <div className='lesson-element__meaning'>{currentElement.meaning}</div>
          {currentElement.mnemonics && (
            <div className='lesson-element__mnemonics'>
              <p>{currentElement.mnemonics}</p>
            </div>
          )}
        </div>
        <div className='lesson-element__navigation'>
          <button
            className='lesson-element__button lesson-element__button--prev'
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            &#8592; Previous
          </button>
          <button
            className='lesson-element__button lesson-element__button--next'
            onClick={handleNext}
          >
            {currentIndex === lessonElements.length - 1 ? 'Quiz Time!' : 'Next'} &#8594;
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className='compose-lessons__quiz'>
      <QuizCard
        currentItem={lessonElements[quizIndex]}
        onNextItem={handleQuizNextItem}
        onIncorrectAnswer={handleIncorrectAnswer}
        isLastItem={quizIndex === lessonElements.length - 1}
      />
    </div>
  )
}

export default ComposeLesson
