import React from 'react'
import HeaderLoggedIn from '../components/HeaderLoggedIn'
import Subscription from '../components/Subscription'

function PaymentLanding() {
    return (
        <div>
            <HeaderLoggedIn />
            <Subscription />
        </div>
    )
}

export default PaymentLanding