// ReviewForecastCard.js
import React from 'react'
import './ReviewForecastCard.css'

const ReviewForecastCard = () => {
  return (
    <div className='card review-forecast-card'>
      <h2 className='review-forecast-card__title'>Review Forecast</h2>
      <p className='review-forecast-card__description'>Your forecasted reviews will appear here.</p>
      {/* <button className="lesson-card__button">Start Lessons</button> */}
      <div className='review-forecast-card__image'></div>
    </div>
  )
}

export default ReviewForecastCard
