import React from 'react'
import './Spinner.css'

function Spinner() {
  return (
    <div className='spinner'>
      <div className='spinner__circle'></div>
    </div>
  )
}

export default Spinner
