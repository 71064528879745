import {
  fetchLevelData,
  fetchUserProgress,
  getUserInfo,
  storeDataInLocalStorage,
  storeProgressInLocalStorage,
} from '../utils/api'

export const initializeUserSession = async (userUUID, currentLevel) => {
  try {
    // Fetch level data
    const levelData = await fetchLevelData(currentLevel)
    storeDataInLocalStorage('levelData', levelData)

    // Fetch user progress data
    const userProgress = await fetchUserProgress(userUUID)
    // Convert userProgress into a Map structure
    const userProgressMap = new Map(
      userProgress.map((progressItem) => [progressItem.ElementId, progressItem]),
    )
    storeProgressInLocalStorage('userProgress', userProgressMap)

    const userInfo = await getUserInfo(userUUID)
    storeDataInLocalStorage('userInfo', userInfo)
  } catch (error) {
    console.error('Error initializing user session:', error)
    throw error
  }
}
