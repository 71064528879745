import React, { useState, useEffect } from 'react'
import LessonCard from './LessonCard'
import ReviewCard from './ReviewCard'
import ReviewForecastCard from './ReviewForecastCard'
import LevelProgressCard from './LevelProgressCard'
import { useUserContext } from '../session/UserContext'
import { getDataFromLocalStorage, getProgressFromLocalStorage } from '../utils/api'
import { initializeUserSession } from '../session/Session'
import Spinner from './Spinner'
import './Dashboard.css'

const Dashboard = () => {
  const { userContext } = useUserContext()
  const [level, setLevel] = useState(1) // Default to level 1
  const [progressData, setProgressData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        setLoading(true)
        const userUUID = userContext.userInfo.UserUUID
        const userLevel = userContext.userInfo.UserLevel || 1
        setLevel(userLevel)

        // Retrieve data from local storage
        let levelData = getDataFromLocalStorage('levelData')
        let userProgress = getProgressFromLocalStorage('userProgress') || new Map()

        if (!levelData || userProgress.size === 0) {
          // If data is not in local storage, re-initialize the session and wait for it to complete
          await initializeUserSession(userUUID, userLevel)
          levelData = getDataFromLocalStorage('levelData')
          userProgress = getProgressFromLocalStorage('userProgress') || new Map()
        }

        // Map progress data to corresponding items in levelData using the Map structure
        const mappedProgress = levelData.map((item) => {
          const progressRecord = userProgress.get(item.SK) || {} // Retrieve from Map
          return {
            PK: item.PK,
            SK: item.SK,
            symbol: item.symbol,
            ProficiencyLevel: progressRecord.ProficiencyLevel || 0,
          }
        })

        // Group results into radicals, hanzi, and vocabulary
        const groupedData = {
          progress: mappedProgress
            .filter((item) => item.SK.startsWith('HANZI#'))
            .reduce((acc, curr) => acc + (curr?.ProficiencyLevel >= 5 ? 1 : 0), 0),
          totalHanzi: mappedProgress.filter((item) => item.SK.startsWith('HANZI#')).length,
          radicals: mappedProgress.filter((item) => item.SK.startsWith('RADICAL#')),
          hanzi: mappedProgress.filter((item) => item.SK.startsWith('HANZI#')),
        }

        setProgressData(groupedData)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    if (userContext.isAuthenticated && userContext.userInfo) {
      loadDashboardData()
    }
  }, [userContext])

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <div className='dashboard'>
      <div className='dashboard__container'>
        {/* <h3 className="dashboard__greeting">Welcome{userContext.userInfo?.Username ? ", "+ userContext.userInfo?.Username : ''}</h3> */}
        <div className='dashboard__row'>
          <LessonCard />
          <ReviewCard />
        </div>
        <div className='dashboard__row'>
          <LevelProgressCard
            level={level}
            progress={progressData.progress}
            totalHanzi={progressData.totalHanzi}
            radicals={progressData.radicals}
            hanzi={progressData.hanzi}
          />
        </div>
        <div className='dashboard__row'>
          <ReviewForecastCard />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
