import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import './OverviewContent.css'
import Spinner from './Spinner'
import { API_LEARNING_CONTENT_ENDPOINT, DIFFICULTIES } from '../constants/common'

function OverviewContent() {
  const navigate = useNavigate()
  const { contentType } = useParams()
  const location = useLocation()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const handleElementClick = (symbol) => {
    navigate(`/${contentType}/${symbol}`)
  }

  const query = new URLSearchParams(location.search)
  let difficulty = query.get('difficulty')

  if (!difficulty || !(difficulty in DIFFICULTIES)) {
    difficulty = DIFFICULTIES.Newbie.name
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_LEARNING_CONTENT_ENDPOINT}/LearningContent/overview/${contentType}?difficulty=${difficulty}`,
        )
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`)
        }
        const result = await response.json()
        setData(result)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [contentType, difficulty])

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <div className='overview__error'>Error: {error}</div>
  }

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className='overview__no-data'>
        No data found for the specified difficulty and content type.
      </div>
    )
  }

  return (
    <div className='overview'>
      <h1 className='overview__title'>
        {contentType.charAt(0).toUpperCase() + contentType.slice(1)} Overview - {difficulty}
      </h1>
      {Object.keys(data).map((level) => (
        <div key={level} className='overview__level'>
          <h2 className='overview__level-title'>Level {level}</h2>
          <div className='overview__items'>
            {data[level].map((item, index) => (
              <div
                key={index}
                className='overview__item'
                onClick={() => handleElementClick(item.symbol)}
              >
                <strong className='overview__item-symbol'>{item.symbol}</strong>{' '}
                <span className='overview__item-meaning'>{item.meaning}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default OverviewContent
