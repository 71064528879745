import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './LevelContent.css'
import Spinner from './Spinner'
import { API_LEARNING_CONTENT_ENDPOINT } from '../constants/common'

function LevelContent() {
  const { levelNumber } = useParams()
  const navigate = useNavigate()

  // State to hold the level data
  const [level, setLevel] = useState({
    radicals: [],
    hanzi: [],
    vocabulary: [],
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // Fetch the level data from the API
  useEffect(() => {
    const fetchLevelData = async () => {
      try {
        const response = await fetch(
          `${API_LEARNING_CONTENT_ENDPOINT}/LearningContent/level/${levelNumber}`,
        )
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`)
        }
        const data = await response.json()

        // Process and organize the data into radicals, hanzi, and vocabulary
        const organizedData = {
          radicals: [],
          hanzi: [],
          vocabulary: [],
        }

        data.forEach((item) => {
          const { symbol, meaning, SK } = item
          if (SK.startsWith('RADICAL#')) {
            organizedData.radicals.push({ symbol, meaning })
          } else if (SK.startsWith('HANZI#')) {
            organizedData.hanzi.push({ symbol, meaning })
          } else if (SK.startsWith('VOCABULARY#')) {
            organizedData.vocabulary.push({ symbol, meaning })
          }
        })

        setLevel(organizedData)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchLevelData()
  }, [levelNumber])

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (level.radicals.length === 0 && level.hanzi.length === 0 && level.vocabulary.length === 0) {
    return <div>Level {levelNumber} not found</div>
  }

  const handleRadicalClick = (symbol) => {
    navigate(`/radicals/${symbol}`)
  }

  const handleHanziClick = (symbol) => {
    navigate(`/hanzi/${symbol}`)
  }

  const handleVocabularyClick = (symbol) => {
    navigate(`/vocabulary/${symbol}`)
  }

  return (
    <div className='level'>
      <h1 className='level__title'>Level {levelNumber}</h1>

      <div className='level__section'>
        <h2>Radicals</h2>
        <div className='level__items'>
          {level.radicals.map((radical, index) => (
            <div
              key={index}
              className='level__item'
              onClick={() => handleRadicalClick(radical.symbol)}
            >
              <div className='level__item-symbol'>{radical.symbol}</div>
              <div className='level__item-meaning'>{radical.meaning}</div>
            </div>
          ))}
        </div>
      </div>

      <div className='level__section'>
        <h2>Hanzi</h2>
        <div className='level__items'>
          {level.hanzi.map((zi, index) => (
            <div key={index} className='level__item' onClick={() => handleHanziClick(zi.symbol)}>
              <div className='level__item-symbol'>{zi.symbol}</div>
              <div className='level__item-meaning'>{zi.meaning}</div>
            </div>
          ))}
        </div>
      </div>

      <div className='level__section'>
        <h2>Vocabulary</h2>
        <div className='level__items'>
          {level.vocabulary.map((word, index) => (
            <div
              key={index}
              className='level__item'
              onClick={() => handleVocabularyClick(word.symbol)}
            >
              <div className='level__item-symbol'>{word.symbol}</div>
              <div className='level__item-meaning'>{word.meaning}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LevelContent
