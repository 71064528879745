import React, { useState, useEffect } from 'react'
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js'
import { Link, useNavigate } from 'react-router-dom'
import { awsConfig } from '../aws-config'
import './SignInComponent.css'
import PropTypes from 'prop-types'

const poolData = {
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.clientId,
}

const userPool = new CognitoUserPool(poolData)

function SignInComponent({ setIsAuthenticated }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [step, setStep] = useState(1) // 1: email, 2: verification code and new password
  const [timer, setTimer] = useState(0) // countdown timer for resend
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(countdown)
    }
    if (timer === 0) {
      setIsResendDisabled(false)
    }
  }, [timer])

  const handleSignIn = (e) => {
    e.preventDefault()
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    user.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        try {
          setIsAuthenticated(true)
          navigate('/dashboard')
        } catch (err) {
          setError('Failed to load user information')
          console.error(err)
          console.log(result)
        }
      },
      onFailure: (err) => {
        setError(err.message || JSON.stringify(err))
      },
    })
  }

  const handleForgotPassword = () => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    user.forgotPassword({
      onSuccess: (data) => {
        setStep(2) // Move to next step (code verification and new password)
        setTimer(60) // Start 30 second timer for Resend
        setIsResendDisabled(true)
        console.log(data)
      },
      onFailure: (err) => {
        // Custom error handling for "User not found" scenario
        if (err.code === 'UserNotFoundException') {
          setError('Email is not recognized. Did you register with another email?')
        } else {
          setError(err.message || JSON.stringify(err))
        }
      },
    })
  }

  const handleResetPassword = () => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    })

    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        setStep(1)
        setShowForgotPassword(false)
        setError('')
      },
      onFailure: (err) => {
        setError(err.message || JSON.stringify(err))
      },
    })
  }

  return (
    <div className='sign-in'>
      <div className='sign-in__container'>
        {showForgotPassword ? (
          <div>
            <h2 className='sign-in__title'>Reset Password</h2>
            {step === 1 ? (
              <>
                <p>Enter your email to receive a verification code.</p>
                <input
                  className='sign-in__input'
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button
                  onClick={handleForgotPassword}
                  className='sign-in__primary-button'
                  disabled={isResendDisabled}
                >
                  {timer > 0 ? `Resend code in ${timer}s` : 'Send Code'}
                </button>
              </>
            ) : (
              <>
                <p>Enter the verification code and your new password.</p>
                <button
                  onClick={handleForgotPassword}
                  className={
                    isResendDisabled
                      ? 'sign-in__secondary-button--disabled'
                      : 'sign-in__secondary-button'
                  }
                  disabled={isResendDisabled}
                >
                  {timer > 0 ? `Resend code in ${timer}s` : 'Resend verification code'}
                </button>
                <input
                  className='sign-in__input'
                  type='text'
                  placeholder='Verification Code'
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  required
                />
                <input
                  className='sign-in__input'
                  type='password'
                  placeholder='New Password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <button onClick={handleResetPassword} className='sign-in__primary-button'>
                  Reset Password
                </button>
              </>
            )}
            {error && <p className='sign-in__error'>{error}</p>}
            <p>
              <button
                onClick={() => setShowForgotPassword(false)}
                className='sign-in__secondary-button'
              >
                Back to Sign In
              </button>
            </p>
          </div>
        ) : (
          <>
            <h2 className='sign-in__title'>Sign In</h2>
            <form className='sign-in__form' onSubmit={handleSignIn} onKeyDown={(event) => {
              if (event.key === 'Enter') {
              handleSignIn(event)
              }
            }}>
              <div className='sign-in__form-group'>
                <label className='sign-in__label' htmlFor='email'>
                  Email
                </label>
                <input
                  className='sign-in__input'
                  id='email'
                  type='email'
                  placeholder='qilin@qilinchinese.com'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className='sign-in__form-group'>
                <label className='sign-in__label' htmlFor='password'>
                  Password
                </label>
                <input
                  className='sign-in__input'
                  id='password'
                  type='password'
                  placeholder='ILoveLearningHanzi2024!'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <p>
                <button
                  onClick={() => setShowForgotPassword(true)}
                  className='sign-in__secondary-button'
                >
                  Forgot Password?
                </button>
              </p>
              <button className='sign-in__primary-button' type='submit'>
                Sign In
              </button>
              {error && <p className='sign-in__error'>{error}</p>}
            </form>
            <div>
              <p>
                Don&apos;t have an account yet?{' '}
                <Link className='sign-in__secondary-button' to='/signup'>
                  Register
                </Link>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

SignInComponent.propTypes = {
  setIsAuthenticated: PropTypes.func.isRequired,
}

export default SignInComponent
