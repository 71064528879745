import React from 'react'
import './AboutQilin.css'
import mnemonicsIcon from './assets/mnemonics.png'
import radicalsIcon from './assets/radicals.png'
import charactersIcon from './assets/characters.png'
import vocabularyIcon from './assets/vocabulary.png'
import lessonsIcon from './assets/lessons.png'
import srsIcon from './assets/srs.png'

function AboutQilin() {
  return (
    <div className='about-qilin' id='about_section'>
      <div className='container'>
        <div className='about-qilin__content'>
          <h1 className='about-qilin__title'>What makes Qilin&apos;s method effective?</h1>
          <div className='about-qilin__cards'>
            <div className='about-qilin__card'>
              <img
                src={mnemonicsIcon}
                alt='Mnemonics'
                className='about-qilin__card-icon'
                loading='lazy'
              />
              <h2 className='about-qilin__card-title'>Mnemonics</h2>
              <p className='about-qilin__card-text'>
                You&apos;ll learn radicals, hanzi (Chinese characters), and words with creative
                mnemonics that Qilin developed, making the journey fun and boosting recall.
              </p>
            </div>
            <div className='about-qilin__card'>
              <img
                src={radicalsIcon}
                alt='Radicals'
                className='about-qilin__card-icon'
                loading='lazy'
              />
              <h2 className='about-qilin__card-title'>Radicals</h2>
              <p className='about-qilin__card-text'>
                You&apos;ll learn the &apos;alphabet&apos; of hanzi (Chinese characters) - radicals.
                We use them to help you build hanzi quickly and create memorable mnemonics that
                last.
              </p>
            </div>
            <div className='about-qilin__card'>
              <img
                src={charactersIcon}
                alt='Characters'
                className='about-qilin__card-icon'
                loading='lazy'
              />
              <h2 className='about-qilin__card-title'>Hanzi</h2>
              <p className='about-qilin__card-text'>
                Master over 2,000 essential hanzi that we carefully selected and organized,
                guaranteed to cover 98% of Chinese daily reading.
              </p>
            </div>
            <div className='about-qilin__card'>
              <img
                src={vocabularyIcon}
                alt='Vocabulary'
                className='about-qilin__card-icon'
                loading='lazy'
              />
              <h2 className='about-qilin__card-title'>Vocabulary</h2>
              <p className='about-qilin__card-text'>
                Expand your vocabulary with over 6,000 commonly used Chinese words, which are
                verified to be practical and relevant.
              </p>
            </div>
            <div className='about-qilin__card'>
              <img
                src={lessonsIcon}
                alt='Lessons & Reviews'
                className='about-qilin__card-icon'
                loading='lazy'
              />
              <h2 className='about-qilin__card-title'>Lessons & Reviews</h2>
              <p className='about-qilin__card-text'>
                Engage with interactive lessons and reviews that reinforce what you’ve learned,
                making recall second nature.
              </p>
            </div>
            <div className='about-qilin__card'>
              <img
                src={srsIcon}
                alt='Spaced Repetition System'
                className='about-qilin__card-icon'
                loading='lazy'
              />
              <h2 className='about-qilin__card-title'>Spaced Repetition</h2>
              <p className='about-qilin__card-text'>
                Our Spaced Repetition System (SRS) schedules reviews at optimal times for you,
                ensuring you retain information long-term.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutQilin
