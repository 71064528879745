import React, { useState, useEffect } from 'react'
import {
  getDataFromLocalStorage,
  getProgressFromLocalStorage,
  updateUserProgressBatch,
  storeProgressInLocalStorage,
} from '../utils/api'
import './ComposeReview.css'
import Spinner from './Spinner'
import QuizCard from './QuizCard'
import { ProficiencyLevelToSRSTime } from '../utils/helper'

const ComposeReview = () => {
  const [reviewItems, setReviewItems] = useState([])
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [allReviewsCompleted, setAllReviewsCompleted] = useState(false)
  const [incorrectItems, setIncorrectItems] = useState([])

  useEffect(() => {
    const fetchReviewItems = () => {
      try {
        const userProgress = getProgressFromLocalStorage('userProgress') || new Map()
        const levelData = getDataFromLocalStorage('levelData') || []

        const now = new Date().getTime()

        const itemsForReview = Array.from(userProgress.values()).filter(
          (item) => item.NextReviewTime <= now,
        )

        const elementsToReview = itemsForReview
          .map((reviewItem) => {
            return levelData.find((levelItem) => levelItem.SK === reviewItem.ElementId)
          })
          .filter(Boolean)

        if (elementsToReview.length === 0) {
          setAllReviewsCompleted(true)
        } else {
          setReviewItems(elementsToReview)
        }
      } catch (err) {
        setError('Failed to load review items')
      } finally {
        setLoading(false)
      }
    }

    fetchReviewItems()
  }, [])

  const handleIncorrectAnswer = (incorrectItem) => {
    setIncorrectItems((prevItems) => [...prevItems, incorrectItem])
  }

  const handleNextItem = () => {
    const updatedItems = [...reviewItems]

    // Reinsert an incorrect item after every two correct answers
    if (incorrectItems.length > 0 && currentItemIndex % 2 === 0) {
      const incorrectItem = incorrectItems.shift() // Remove first incorrect item
      updatedItems.push(incorrectItem) // Append it back to review
      setIncorrectItems([...incorrectItems]) // Update the incorrect items list
    }

    if (currentItemIndex < updatedItems.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1)
    } else {
      finishReview()
    }

    setReviewItems(updatedItems) // Update the review items with reinsertion
  }

  const finishReview = async () => {
    const userProgress = getProgressFromLocalStorage('userProgress') || new Map()
    const dirtyProgress = getProgressFromLocalStorage('dirtyProgress') || new Map()
    const now = new Date().getTime()
    const userUUID = getDataFromLocalStorage('userInfo').UserUUID

    const updatedItemsSet = new Set()

    reviewItems.forEach((item) => {
      if (!updatedItemsSet.has(item.SK)) {
        const existingProgress = userProgress.get(item.SK)
        const newProficiencyLevel = Math.min((existingProgress?.ProficiencyLevel || 0) + 1, 25)

        const progress = {
          ElementId: item.SK,
          UserUUID: userUUID,
          LastReviewTime: now,
          ProficiencyLevel: newProficiencyLevel,
          NextReviewTime: now + ProficiencyLevelToSRSTime(newProficiencyLevel),
        }

        userProgress.set(item.SK, progress)
        dirtyProgress.set(item.SK, progress)

        updatedItemsSet.add(item.SK)
      }
    })

    storeProgressInLocalStorage('userProgress', userProgress)
    storeProgressInLocalStorage('dirtyProgress', dirtyProgress)

    if (dirtyProgress.size > 0) {
      updateUserProgressBatch(Array.from(dirtyProgress.values()))
        .then(() => {
          dirtyProgress.clear()
          storeProgressInLocalStorage('dirtyProgress', dirtyProgress)
        })
        .catch((err) => console.error('Failed to update progress:', err))
    }

    setAllReviewsCompleted(true)
  }

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (allReviewsCompleted) {
    return <div>All reviews completed. Good job!</div>
  }

  const currentItem = reviewItems[currentItemIndex]

  return (
    <div className='review-page'>
      <QuizCard
        currentItem={currentItem}
        onNextItem={handleNextItem}
        onIncorrectAnswer={handleIncorrectAnswer}
        isLastItem={currentItemIndex === reviewItems.length - 1}
      />
    </div>
  )
}

export default ComposeReview
