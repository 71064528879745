const API_LEARNING_CONTENT_ENDPOINT = 'https://aro6yyxdcg.execute-api.us-east-1.amazonaws.com/prod'

const API_USER_SERVICE_ENDPOINT = 'https://988ryl2o2i.execute-api.us-east-1.amazonaws.com/dev'

const DIFFICULTIES = {
  Newbie: {
    name: 'Newbie',
    translation: '新 手',
  },
  Explorer: {
    name: 'Explorer',
    translation: '探索者',
  },
  Adventurer: {
    name: 'Adventurer',
    translation: '冒险家',
  },
  Trailblazer: {
    name: 'Trailblazer',
    translation: '开拓者',
  },
  Maverick: {
    name: 'Maverick',
    translation: '独行者',
  },
  Guru: {
    name: 'Guru',
    translation: '大 师',
  },
  Ninja: {
    name: 'Ninja',
    translation: '忍 者',
  },
  Wizard: {
    name: 'Wizard',
    translation: '巫 师',
  },
  Mastermind: {
    name: 'Mastermind',
    translation: '智 者',
  },
}

export { API_LEARNING_CONTENT_ENDPOINT, API_USER_SERVICE_ENDPOINT, DIFFICULTIES }
