import React from 'react'
import ComposeLesson from './ComposeLesson'
import PropTypes from 'prop-types'

function LessonComponent({ userUUID, elements }) {
  return <ComposeLesson userUUID={userUUID} elements={elements} />
}

LessonComponent.propTypes = {
  userUUID: PropTypes.string.isRequired,
  elements: PropTypes.array.isRequired,
}

export default LessonComponent
