import React from 'react'
import { useNavigate } from 'react-router-dom'
import './HeaderLoggedIn.css'
import DropdownMenu from './DropdownMenu'
import ProfileDropdownMenu from './ProfileDropdownMenu'
import { DIFFICULTIES } from '../constants/common'
import logo from './images/logo.png'

function HeaderLoggedIn() {
  const navigate = useNavigate()

  const handleLogoClick = () => {
    if (window.location.pathname === '/dashboard') {
      window.location.reload()
    } else {
      navigate('/dashboard')
    }
  }

  const createLevelSections = () => {
    return Object.keys(DIFFICULTIES).map((difficulty, index) => {
      const startLevel = index * 10 + 1
      const levels = Array.from({ length: 10 }, (_, i) => ({
        label: `${startLevel + i}`,
        link: `/level/${startLevel + i}`,
      }))

      return {
        label: `${DIFFICULTIES[difficulty].translation} ${DIFFICULTIES[difficulty].name}`,
        items: levels,
      }
    })
  }

  const createOverviewSections = (contentType) => {
    return Object.keys(DIFFICULTIES).map((difficulty, index) => {
      const startLevel = index * 10 + 1
      const endLevel = startLevel + 9
      return {
        label: `${DIFFICULTIES[difficulty].translation} ${DIFFICULTIES[difficulty].name}`,
        items: [
          {
            label: `Levels ${startLevel}-${endLevel}`,
            link: `/overview/${contentType}/?difficulty=${DIFFICULTIES[difficulty].name}`,
          },
        ],
      }
    })
  }

  const levelsSections = createLevelSections()
  const radicalsSections = createOverviewSections('radicals')
  const hanziSections = createOverviewSections('hanzi')
  const vocabularySections = createOverviewSections('vocabulary')

  return (
    <header className='header'>
      <div className='header__container'>
        <div className='header__logo' onClick={handleLogoClick}>
          <img src={logo} alt='Qilin logo' className='header__logo-image' />
          <div className='header__logo-text'>ilinChinese</div>
        </div>
        <nav className='header__nav'>
          <DropdownMenu title='Levels' hoverText='等 级' sections={levelsSections} />
          <DropdownMenu title='Radicals' hoverText='部 首' sections={radicalsSections} />
          <DropdownMenu title='Hanzi' hoverText='汉 字' sections={hanziSections} />
          <DropdownMenu title='Vocabulary' hoverText='词 汇' sections={vocabularySections} />
          <ProfileDropdownMenu />
        </nav>
      </div>
    </header>
  )
}

export default HeaderLoggedIn
