import React from 'react'
import Header from '../components/Header'
import CourseSection from '../components/CourseSection'
import Footer from '../components/Footer'
import AboveFold from '../components/AboveFold'
import AboutQilin from '../components/AboutQilin'

function HomeLoggedOut() {
  return (
    <div>
      <Header />
      <AboveFold />
      <AboutQilin />
      <CourseSection />
      <Footer />
    </div>
  )
}

export default HomeLoggedOut
