// LessonCard.js
import React from 'react'
import './LessonCard.css'
import { useNavigate } from 'react-router-dom'

const LessonCard = () => {
  const navigate = useNavigate()
  return (
    <div className='card lesson-card'>
      <div className='lesson-card__header'>
        <h2 className='lesson-card__title'>Lessons</h2>
        {/* <div className='lesson-card__count'>20</div> */}
        {/* <div className="lesson-card__status">新</div> */}
      </div>
      <p className='lesson-card__description'>We composed these lessons just for you.</p>
      <button className='lesson-card__button' onClick={() => navigate('/lesson')}>
        Start Lessons
      </button>
      <div className='lesson-card__image'></div>
    </div>
  )
}

export default LessonCard
