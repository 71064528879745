import React, { createContext, useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { awsConfig } from '../aws-config'
import { getUserInfo, storeDataInLocalStorage } from '../utils/api'
import { initializeUserSession } from './Session'
import PropTypes from 'prop-types'

const UserContext = createContext()

const poolData = {
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.clientId,
}

const userPool = new CognitoUserPool(poolData)

export const UserProvider = ({ children }) => {
  const [userContext, setUserContext] = useState({
    isAuthenticated: false,
    userInfo: null,
    sessionExpiration: null,
  })

  const navigate = useNavigate()

  // Function to handle session renewal
  const renewSession = () => {
    const currentUser = userPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession(async (err, session) => {
        if (err) {
          console.error('Failed to renew session:', err)
          logoutUser()
          return
        }
        // Update context with new session information
        const idToken = session.getIdToken()
        const expiration = idToken.getExpiration() * 1000 // Convert to milliseconds
        try {
          const userInfo = await getUserInfo(idToken.payload.sub) // Fetch user info
          setUserContext((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            userInfo,
            sessionExpiration: expiration,
          }))
          storeDataInLocalStorage('sessionExpiration', expiration)
          storeDataInLocalStorage('userInfo', userInfo)
        } catch (error) {
          console.error('Failed to fetch user info during session renewal:', error)
          logoutUser() // Logout if user info retrieval fails
        }
      })
    }
  }

  // Function to log out the user
  const logoutUser = () => {
    const currentUser = userPool.getCurrentUser()
    if (currentUser) {
      currentUser.signOut()
      navigate('/')
    }
    setUserContext({
      isAuthenticated: false,
      userInfo: null,
      sessionExpiration: null,
    })
    localStorage.clear() // Clear all session-related data
  }

  // Check session expiration on app start
  useEffect(() => {
    const currentUser = userPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession(async (err, session) => {
        if (err) {
          console.error('Failed to get session:', err)
          logoutUser()
          return
        }
        const idToken = session.getIdToken()
        const expiration = idToken.getExpiration() * 1000 // Convert to milliseconds
        const currentTime = new Date().getTime()

        if (currentTime < expiration) {
          try {
            const userInfo = await getUserInfo(idToken.payload.sub) // Fetch user info
            setUserContext({
              isAuthenticated: true,
              userInfo,
              sessionExpiration: expiration,
            })
            initializeUserSession(userInfo.UserUUID, userInfo.UserLevel)
          } catch (error) {
            console.error('Failed to fetch user info on app start:', error)
            logoutUser() // Logout if user info retrieval fails
          }
        } else {
          logoutUser() // Session expired
        }
      })
    }
  }, [])

  // Check session expiration periodically
  useEffect(() => {
    const interval = setInterval(() => {
      if (userContext.sessionExpiration) {
        const currentTime = new Date().getTime()
        const timeRemaining = userContext.sessionExpiration - currentTime
        if (timeRemaining <= 5 * 60 * 1000) {
          // renew session 5 minutes before expiration
          renewSession()
        }
      }
    }, 60000) // Check every minute

    return () => clearInterval(interval)
  }, [userContext.sessionExpiration])

  return (
    <UserContext.Provider value={{ userContext, setUserContext, logoutUser }}>
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useUserContext = () => useContext(UserContext)
