import React from 'react'
import './LevelProgressCard.css'
import PropTypes from 'prop-types'

const LevelProgressCard = ({ level, progress, totalHanzi, radicals = [], hanzi = [] }) => {
  return (
    <div className='level-progress-card'>
      <h3>Level {level} Progress</h3>
      <div>
        <div className='progress-bar-container'>
          <div className='progress-bar' style={{ width: `${(progress / totalHanzi) * 100}%` }} />
        </div>
        <div className='progress-bar-label'>
          {progress}/{totalHanzi} hanzi passed
        </div>
      </div>
      <div className='radicals-section'>
        <h4>Radicals</h4>
        <div className='level-progress__items'>
          {radicals.map((radical, index) => (
            <div key={index} className='level-progress__item'>
              <div className='level-progress__radical-item-symbol'>{radical.symbol}</div>
              <div className='level-progress__item-progress-container'>
                <div className='level-progress__item-progress'>
                  <div
                    className='level-progress__item-progress-bar'
                    style={{ width: `${(Math.min(radical.ProficiencyLevel, 5) / 5) * 100}%` }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='hanzi-section'>
        <h4>Hanzi</h4>
        <div className='level-progress__items'>
          {hanzi.map((zi, index) => (
            <div key={index} className='level-progress__item'>
              <div className='level-progress__hanzi-item-symbol'>{zi.symbol}</div>
              <div className='level-progress__item-progress-container'>
                <div className='level-progress__item-progress'>
                  <div
                    className='level-progress__item-progress-bar'
                    style={{ width: `${(Math.min(zi.ProficiencyLevel, 5) / 5) * 100}%` }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

LevelProgressCard.propTypes = {
  level: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  totalHanzi: PropTypes.number.isRequired,
  radicals: PropTypes.array.isRequired,
  hanzi: PropTypes.array.isRequired,
}

export default LevelProgressCard
