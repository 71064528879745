import React, { useState, useEffect } from 'react'
import './CourseSection.css'
import { useNavigate } from 'react-router-dom'

function CourseSection() {
  const navigate = useNavigate()

  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })

  useEffect(() => {
    const targetDate = new Date('2024-10-01T00:00:00')

    const updateCountdown = () => {
      const now = new Date().getTime()
      const difference = targetDate - now

      if (difference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 })
        return
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)

      setTimeLeft({ days, hours, minutes, seconds })
    }

    updateCountdown()
    const intervalId = setInterval(updateCountdown, 1000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <section className='course_section' id='coming_soon_section'>
      <h1 className='course_section__title'>We are coming soon</h1>
      <p className='course_section__text'>
        We are working hard on getting your premium learning materials ready by October 1st, 2024!
      </p>
      <p className='course_section__text'>
        We appreciate your patience, and would like to offer you free membership for the first 3
        months if you sign up today!
      </p>
      <p className='course_section__text'>
        Afterwards you can choose to continue your membership at a special discounted rate.
      </p>
      <button className='course_section__button' onClick={() => navigate('/signup')}>
        Sign up
      </button>
      <div className='coming-soon__countdown'>
        <h2 className='coming-soon__countdown-title'>Countdown to Launch:</h2>
        <div className='coming-soon__countdown-timer'>
          <div className='coming-soon__countdown-item'>
            <span className='coming-soon__countdown-number'>{timeLeft.days}</span>
            <span className='coming-soon__countdown-label'>Days</span>
          </div>
          <div className='coming-soon__countdown-item'>
            <span className='coming-soon__countdown-number'>{timeLeft.hours}</span>
            <span className='coming-soon__countdown-label'>Hours</span>
          </div>
          <div className='coming-soon__countdown-item'>
            <span className='coming-soon__countdown-number'>{timeLeft.minutes}</span>
            <span className='coming-soon__countdown-label'>Minutes</span>
          </div>
          <div className='coming-soon__countdown-item'>
            <span className='coming-soon__countdown-number'>{timeLeft.seconds}</span>
            <span className='coming-soon__countdown-label'>Seconds</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CourseSection
