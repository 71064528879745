import React from 'react'
import { useNavigate } from 'react-router-dom'
import './AboveFold.css'

function AboveFold() {
  const scrollToComingSoonSection = () => {
    document.getElementById('coming_soon_section').scrollIntoView({ behavior: 'smooth' })
  }
  const navigate = useNavigate()

  return (
    <div className='aboveFold__image-container'>
      <div className='aboveFold__overlay'>
        <div className='aboveFold__text-container'>
          <h1 className='aboveFold__text'>Learning Chinese is Fun and Easy,</h1>
          <h1 className='aboveFold__text'>with the right method!</h1>
          <h1 className='aboveFold__text aboveFold__text-Qilin'>-- The Qilin</h1>
        </div>
        <div className='aboveFold__button-container'>
          <button className='aboveFold__button' onClick={scrollToComingSoonSection}>
            Coming Soon
          </button>
          <button className='aboveFold__button' onClick={() => navigate('/signup')}>
            Start Today
          </button>
        </div>
      </div>
    </div>
  )
}

export default AboveFold
