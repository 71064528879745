import LevelContent from '../components/LevelContent'
import React from 'react'
import HeaderLoggedIn from '../components/HeaderLoggedIn'

function Level() {
  return (
    <div>
      <HeaderLoggedIn />
      <LevelContent />
    </div>
  )
}

export default Level
