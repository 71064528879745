// api.js
import { API_USER_SERVICE_ENDPOINT, API_LEARNING_CONTENT_ENDPOINT } from '../constants/common'

export const registerUser = async (email, username, sub) => {
  const response = await fetch(`${API_USER_SERVICE_ENDPOINT}/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
      username: username,
      cognito_sub: sub,
    }),
  })

  if (!response.ok) {
    throw new Error('Failed to register user')
  }

  return await response.json()
}

export const getUserInfo = async (userUUID) => {
  try {
    const response = await fetch(`${API_USER_SERVICE_ENDPOINT}/user/${userUUID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`Failed to retrieve user info: ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching user info:', error)
    throw error
  }
}

export const updateUserProgress = async (
  userUUID,
  elementId,
  proficiencyLevel,
  nextReviewTime,
  lastReviewTime,
) => {
  const response = await fetch(`${API_USER_SERVICE_ENDPOINT}/user/progress`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_uuid: userUUID,
      element_id: elementId,
      proficiency_level: proficiencyLevel,
      next_review_time: nextReviewTime,
      last_review_time: lastReviewTime,
    }),
  })

  if (!response.ok) {
    throw new Error('Failed to store user progress')
  }

  return await response.json()
}

export const updateUserProgressBatch = async (userProgressUpdates) => {
  console.log('userProgressUpdates: ', userProgressUpdates)
  const response = await fetch(`${API_USER_SERVICE_ENDPOINT}/user/progress`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      updates: userProgressUpdates, // Array of progress updates
    }),
  })

  if (!response.ok) {
    throw new Error('Failed to batch store user progress')
  }

  return await response.json()
}

// Utility function to fetch level data
export const fetchLevelData = async (levelNumber) => {
  try {
    const response = await fetch(
      `${API_LEARNING_CONTENT_ENDPOINT}/LearningContent/level/${levelNumber}`,
    )
    if (!response.ok) {
      throw new Error(`Error fetching level data: ${response.statusText}`)
    }
    const data = await response.json()
    return data
  } catch (err) {
    console.error('Error fetching level data:', err)
    throw err // Re-throw the error to be caught in the higher-level function
  }
}

// Utility function to fetch user progress data
export const fetchUserProgress = async (userUUID) => {
  const response = await fetch(`${API_USER_SERVICE_ENDPOINT}/user/progress?user_uuid=${userUUID}`)
  if (!response.ok) {
    throw new Error(`Error fetching user progress: ${response.statusText}`)
  }
  const data = await response.json()
  return data
}

// Function to store data in local storage
export const storeDataInLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data))
}

// Function to get data from local storage
export const getDataFromLocalStorage = (key) => {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : null
}

// Function to store Map in local storage as an object
export const storeProgressInLocalStorage = (key, map) => {
  storeDataInLocalStorage(key, Object.fromEntries(map)) // Convert Map to object
}

// Function to retrieve Map from local storage
export const getProgressFromLocalStorage = (key) => {
  const data = getDataFromLocalStorage(key)
  return data ? new Map(Object.entries(data)) : new Map() // Convert object to Map
}
