import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Header.css'
import logo from './images/logo.png'

function Header() {
  const navigate = useNavigate()
  const handleLogoClick = () => {
    if (window.location.pathname === '/') {
      window.location.reload()
    } else {
      navigate('/')
    }
  }

  return (
    <header className='header__logged-out'>
      <div className='header__logged-out-container'>
        <div className='header__logged-out-logo' onClick={handleLogoClick}>
          <img src={logo} alt='Qilin logo' className='header__logged-out-logo-image' />
          <div className='header__logo-text'>ilinChinese</div>
        </div>
        <nav className='header__logged-out-nav'>
          {/* <a href="#course" className="link">Course</a> */}
          <a href='#about_section' className='header__logged-out-link'>
            About
          </a>
          <button className='header__logged-out-button' onClick={() => navigate('/signup')}>
            Join now
          </button>
        </nav>
      </div>
    </header>
  )
}

export default Header
