import React from 'react'
import './ProfileDropdownMenu.css'
import ProfilePlaceholder from './images/LazyCat.png'
import { useUserContext } from '../session/UserContext'
import { useNavigate } from 'react-router-dom'

function ProfileDropdownMenu() {
  const { logoutUser } = useUserContext()
  const navigate = useNavigate()

  const handleSubscriptionClick = () => {
    navigate('/subscription')
  }

  return (
    <div className='header__dropdown-profile'>
      <div className='header__profile'>
        <img src={ProfilePlaceholder} alt='Profile' className='header__profile-picture' />
      </div>
      <div className='header__dropdown-profile-menu'>
        <button className='header__dropdown-profile-button' onClick={handleSubscriptionClick}>
          Subscribe
        </button>
        <button className='header__dropdown-profile-button' onClick={logoutUser}>
          Logout
        </button>
      </div>
    </div>
  )
}

export default ProfileDropdownMenu


