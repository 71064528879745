import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { awsConfig } from '../aws-config'

const poolData = {
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.clientId,
}

const userPool = new CognitoUserPool(poolData)

export const getCurrentUser = () => {
  return userPool.getCurrentUser()
}

export const checkUserAuthentication = async () => {
  const currentUser = getCurrentUser()
  if (!currentUser) return false

  return new Promise((resolve) => {
    currentUser.getSession((err, session) => {
      if (err || !session.isValid()) {
        resolve(false)
      } else {
        resolve(true)
      }
    })
  })
}
