import React from 'react'
import HeaderLoggedIn from '../components/HeaderLoggedIn'
import Dashboard from '../components/Dashboard'

function HomeLoggedIn() {
  return (
    <div>
      <HeaderLoggedIn />
      <Dashboard />
    </div>
  )
}

export default HomeLoggedIn
