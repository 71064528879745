import React from 'react'
import SignUpComponent from '../components/SignUpComponent'

function SignUp() {
  return (
    <div className='App'>
      <SignUpComponent />
    </div>
  )
}

export default SignUp
