import React from 'react'

function Footer() {
  return (
    <footer style={footerStyle}>
      <div style={footerContentStyle}>
        <p>&copy; 2024 Qilin Chinese. All rights reserved.</p>
        <nav>
          <a href='#privacy' style={footerLinkStyle}>
            Privacy Policy
          </a>
          <a href='#terms' style={footerLinkStyle}>
            Terms of Service
          </a>
          <a href='#contact' style={footerLinkStyle}>
            Contact Us
          </a>
        </nav>
      </div>
    </footer>
  )
}

const footerStyle = {
  padding: '20px',
  backgroundColor: '#000',
  color: '#fff',
  textAlign: 'center',
}

const footerContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const footerLinkStyle = {
  color: '#fff',
  textDecoration: 'none',
  margin: '0 10px',
}

export default Footer
