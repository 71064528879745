import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import PropTypes from 'prop-types'
import './Subscription.css'

// Promise to resolve with Stripe object when Stripe.js loads
const stripePromise = loadStripe(
  'pk_live_51PlwKGP3RGcnIlwTKNwtA1zAu9JxNOxkva7SsywFbjuBDw0fwIzdH2NVsxtEKSFMskOwZCcaHNa2uNDEfjoSHz4U00ga1Ia541',
)

// Checkout component handles Stripe checkout process
const Checkout = ({ buttonText, priceId }) => {
  // handleCheckout function is called when user clicks Subscribe/Purchase button
  const handleCheckout = async () => {
    try {
      // Send POST request to backend with the priceId
      const [response, stripe] = await Promise.all([
        fetch('https://aro6yyxdcg.execute-api.us-east-1.amazonaws.com/prod/Checkout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ priceId }),  // Send the priceId with the request
        }),
        stripePromise,
      ])

      const session = await response.json()

      // Pass session ID obtained from backend and redirect to Stripe's checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      })

      if (result.error) {
        console.error(result.error.message)
      }
    } catch (error) {
      console.error('Error during checkout:', error)
    }
  }

  return (
    <button className='subscription__button' onClick={handleCheckout}>
      {buttonText}
    </button>
  )
}

Checkout.propTypes = {
  buttonText: PropTypes.string.isRequired, // Ensure buttonText is a required string
  priceId: PropTypes.string.isRequired,    // Ensure priceId is a required string
}


export default Checkout
