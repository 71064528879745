import React from 'react'
import RegisteredComingSoon from '../components/RegisteredComingSoon'

function ComingSoon() {
  return (
    <div className='App'>
      <RegisteredComingSoon />
    </div>
  )
}

export default ComingSoon
